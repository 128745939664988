import chevron from '@a/icons/chevron.svg';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { SecondaryBtn } from './buttons';
import { smTxt } from './layout/styles/Typography';
import { flexSpace } from './layout/styles/classes';

export const Form = styled.form<{ submit: boolean; gray?: boolean }>`
    padding: 32px 16px;
    position: relative;
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    background-color: ${({ theme, gray }) => (gray ? theme.colors.light : theme.colors.white)};
    box-shadow: ${({ gray }) => (gray ? 'none' : '0px 12px 32px rgba(44, 128, 195, 0.15)')};
    border-radius: 8px;
    scroll-margin-top: 220px;

    @media (min-width: 1024px) {
        border-radius: 16px;
        padding: 40px;
    }

    ${({ submit }) =>
        submit &&
        css`
            select,
            textarea,
            input,
            > button {
                pointer-events: none;
                visibility: hidden;
            }
        `};

    ${({ gray, theme }) =>
        gray &&
        css`
            input,
            textarea {
                background-color: ${theme.colors.white} !important;
            }
        `};
`;

export const Input = styled.input<{ rows?: number; setColor?: boolean; select?: boolean }>`
    ${smTxt};
    color: ${({ theme, setColor }) =>
        setColor ? 'rgba(44, 128, 195, 0.7)' : theme.colors.primary};
    border: 1px solid #f5f6f8;
    background-color: #f5f6f8;
    border-radius: 8px;
    width: 100%;
    padding: 16px;
    display: block;
    outline: none;
    transition: border-color 0.3s ease-in;
    font-weight: 400;
    margin-bottom: 0;
    line-height: 162%;
    resize: none;

    :hover {
        border-color: ${({ theme }) => theme.colors.primary};
    }

    :focus-visible {
        border-color: ${({ theme }) => theme.colors.primary};
    }

    :focus-within {
        color: ${({ theme }) => theme.colors.primary};
    }

    ::placeholder {
        color: rgba(44, 128, 195, 0.7);
    }

    option {
        text-transform: capitalize;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
        text-fill-color: ${({ theme }) => theme.colors.primary};
        color: ${({ theme }) => theme.colors.primary};
        -webkit-box-shadow: 0 0 0px 1000px #f5f6f8 inset;
        transition: background-color 50000s ease-in-out 0s;
    }

    :not(:placeholder-shown):invalid {
        border-color: #e75842;
    }

    ${({ select }) =>
        select &&
        css`
            background-image: url(${chevron});
            background-position: center right 20px;
            background-repeat: no-repeat;
            appearance: none;

            &::-ms-expand {
                display: none;
            }
        `};
`;

export const Label = styled.label`
    display: block;
    margin-bottom: 24px;

    > span {
        color: ${({ theme }) => theme.colors.gray};
        font-weight: 400;
        text-transform: capitalize;
        margin-bottom: 8px;
        transition: color 0.3s ease-in-out;
        display: block;
    }

    :hover,
    :focus-within {
        > span {
            color: ${({ theme }) => theme.colors.primary};
        }
    }
`;

export const InputFlex = styled.div`
    @media (min-width: 600px) {
        ${flexSpace};

        > label {
            width: calc(50% - 20px);

            :first-of-type {
                width: 50%;
                margin-right: 20px;
            }
        }
    }

    @media (min-width: 1024px) {
        > label {
            width: calc(50% - 40px);

            :first-of-type {
                width: 50%;
                margin-right: 40px;
            }
        }
    }
`;

const StyledThanks = styled.div<{ submit: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    padding: 20px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: inherit;
    z-index: ${({ submit }) => (submit ? 10 : -1)};
    opacity: ${({ submit }) => (submit ? 1 : 0)};
    transform: ${({ submit }) => (submit ? 'scale(1)' : 'scale(0)')};
    transition: all 0.3s ease-in;
    transition-property: opacity, transform;
    background-color: ${({ theme }) => theme.colors.white};

    > h2 {
        margin-top: 0;
        color: ${({ theme }) => theme.colors.dark};
    }

    > p {
        font-size: 1.25rem;
        color: ${({ theme }) => theme.colors.dark};
    }

    > button {
        visibility: ${({ submit }) => (submit ? 'visible' : 'hidden')};
    }

    @media (min-width: 1024px) {
        padding: 40px;
    }
`;

export const Thanks = ({
    submit,
    setSubmit,
}: {
    submit: boolean;
    setSubmit: React.Dispatch<React.SetStateAction<boolean>>;
}) => (
    <StyledThanks submit={submit}>
        <h2>Thank you</h2>
        <p>Someone will be right with you shortly!</p>

        <SecondaryBtn onClick={() => setSubmit(false)} aria-hidden={!submit}>
            Resubmit Form
        </SecondaryBtn>
    </StyledThanks>
);
